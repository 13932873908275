// Partials importieren
@import 'partials/_base';
@import 'partials/_colors';
@import 'partials/_variables';


.react-joyride__tooltip button{
  outline: none !important;
}

.container-highlight {
  margin: 140px 0;
}

.highlight > img {
  right: 0;
  left: 0;
  transform: translateY(-50%);
  width: 50%;
}

.coat-of-arms {
  position: relative;
  outline: none;
  cursor: pointer;
}

.coat-of-arms p {
  /*font-size: 10px;*/
  margin-top: 6px;
  margin-bottom: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #005AAA;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.coat-of-arms:focus .overlay {
  opacity: 1;
  pointer-events: auto;
}

.coat-of-arms img {
  display: block;
  width: 100%;
  height: auto;
}